import { Subject } from 'interfaces/Subject'
import { KnowDocumentRequest, KnowFormat } from 'interfaces/Know'
import { Knower } from 'interfaces/Knower'
import { Course } from 'interfaces/Course'

// Authentication
export const signInRoute = (o?: { redirect?: string; referrerScreen?: string }) => {
    const path = '/auth/signin'
    return generateRoute(path, { redirect: o?.redirect, utm_content: o?.referrerScreen })
}
export const forgotPasswordRoute = (o?: { referrerScreen?: string }) => {
    const path = '/auth/forgot-password'
    return generateRoute(path, { utm_content: o?.referrerScreen })
}
export const signUpRoute = (o?: { redirect?: string; referrerScreen?: string; title?: string }) => {
    const path = '/auth/signup'
    return generateRoute(path, { redirect: o?.redirect, utm_content: o?.referrerScreen, title: o?.title })
}

// Knows
export const knowCreateRoute = '/knows/create/upload'
export const knowImportFlashcardsRoute = '/knows/create/import-flashcards'
export const knowCreateOptionsRoute = (options?: { referrerScreen?: string }) =>
    `/knows/create/upload${options?.referrerScreen ? `?utm_content=${options.referrerScreen}` : ''}`
export const knowCreateDemandRoute = (uuid: string) => `/knows/create/upload?knowDemandUuid=${uuid}`
export const knowExpansionExplanationRoute = '/knows/create-expansion/explanation'
export const knowDemandsRoute = '/knowdemands'
export const knowDemandPreviewRoute = (uuid: string) => `/knowdemands/${uuid}`
export const knowDetailsFlashcardRoute = (o?: {
    makeKnowPrivate?: string
    referrerScreen?: string
    saveToFolderUuid?: string
}) => {
    const path = `/knows/create/details?hasFlashcards=true`
    return generateRoute(path, {
        utm_content: o?.referrerScreen,
        makeKnowPrivate: o?.makeKnowPrivate,
        saveToFolderUuid: o?.saveToFolderUuid,
    })
}
export const knowCreateDetailsRoute = (knowDemandUuid?: string | null, document?: KnowDocumentRequest) =>
    `/knows/create/details?filename=${document?.filename ?? ''}&title=${document?.title ?? ''}&url=${document?.url ?? ''}${
        knowDemandUuid ? `&knowDemandUuid=${knowDemandUuid}` : ''
    }#detail`
export const knowsRoute = (o?: {
    showKnowUploadedCongratulationsDialog: boolean
    wasFlashcardKnowUpload?: boolean
}): string => {
    const path = `/knows`

    return generateRoute(path, {
        showKnowUploadedCongratulationsDialog: o?.showKnowUploadedCongratulationsDialog,
        wasFlashcardKnowUpload: o?.wasFlashcardKnowUpload,
    })
}

export const unindexedKnowRoute = (slug: string, o?: { referrerScreen?: string; referrer?: string }) => {
    const path = `/knows/u/${slug}`
    return generateRoute(path, { utm_content: o?.referrerScreen, referrer: o?.referrer })
}

export const knowRoute = (
    slug: string,
    o?: { referrerScreen?: string; referrer?: string; sid?: string; query?: string }
) => {
    const path = `/knows/${slug}`
    return generateRoute(path, { utm_content: o?.referrerScreen, referrer: o?.referrer, sid: o?.sid, query: o?.query })
}

export const knowViewRoute = (slug: string, o?: { referrerScreen?: string; referrer?: string }) => {
    const path = `/knows/${slug}/view`
    return generateRoute(path, { utm_content: o?.referrerScreen, referrer: o?.referrer })
}

export const knowContentRoute = (slug: string, o?: { referrerScreen?: string; referrer?: string }) => {
    const path = `/knows/${slug}/content`
    return generateRoute(path, { utm_content: o?.referrerScreen, referrer: o?.referrer })
}

export const knowEditRoute = (uuid: string) => `/knows/${uuid}/edit`
export const genericKnowRoute = (
    websitePath: string,
    o?: { referrerScreen?: string; referrer?: string; query?: string }
) => {
    return generateRoute(websitePath, { utm_content: o?.referrerScreen, referrer: o?.referrer, query: o?.query })
}

// About
export const aiUsageRoute = '/about/knowunity-ai'
export const companyRoute = '/about/company'
export const advertiseRoute = '/about/advertise'
export const calendlyRoute = '/about/calendly'
export const parentsArticleRoute = (uid: string) => `/about/for-parents/blog/${uid}`
export const becomeKnowerRoute = '/about/become-knower'
export const expansionRoute = '/expansion'
export const downloadRoute = '/about/download'
export const downloadOptionsRoute = (options?: { referrerScreen?: string }) =>
    `/about/download${options?.referrerScreen ? `?utm_content=${options.referrerScreen}` : ''}`
export const safetyRoute = '/about/safety'
// App
export const appFeedsRoute = '/app/feeds'
export const appSearchRoute = '/app/search'
export const appOnboardingRoute = '/app/onboarding'
export const appOnboardingRouteOptions = (o?: { referrerScreen?: string; redirect?: string }) =>
    `/app/onboarding${o?.referrerScreen ? `?utm_content=${o.referrerScreen}` : ''}${
        o?.redirect ? `&redirect=${o.redirect}` : ''
    }`
export const appSearchRouteOptions = (
    searchQuery: string,
    subjectId?: number,
    o?: { referrerScreen?: string; knowFormat?: KnowFormat }
) =>
    `/app/search?query=${searchQuery}${subjectId ? `&subjectId=${subjectId}` : ''}${
        o?.referrerScreen ? `&utm_content=${o.referrerScreen}` : ''
    }${o?.knowFormat ? `&knowFormat=${o.knowFormat}` : ''}`
export const appLibraryRoute = '/app/library'
export const appLibraryFolderRoute = (slug: string) => `/app/library/${slug}`
export const appFlashcardsRoute = '/app/flashcards'
export const appMyKnowsRoute = '/app/knows'
export const appSubjectsKnowsRoute = '/app/subjects/knows'
export const appSubjectsKnowsOptionRoute = (referrerScreen: string) => `/app/subjects/knows?utm_content=${referrerScreen}`
export const appChatRoute = '/app/chat'
export const appSyncedKnowsOptionRoute = (o: { referrerScreen: string }) =>
    `/app/library/synced?utm_content=${o.referrerScreen}`
export const appEditProfileRoute = '/app/profile'
export const appCreateKnowsRoute = '/app/knows/create'
export const appCreateKnowsOptionRoute = (o?: { referrerScreen?: string; tab?: string; filename?: string }) =>
    `/app/knows/create${o?.referrerScreen ? `?utm_content=${o.referrerScreen}` : ''}${o?.tab ? `&tab=${o.tab}` : ''}${
        o?.filename ? `&filename=${o.filename}` : ''
    }`
export const appCreateKnowsDetailsRoute = `/app/knows/details`
export const appKnowDetailsFlashcardRoute = (o?: {
    makeKnowPrivate?: string
    referrerScreen?: string
    saveToFolderUuid?: string
}) => {
    const path = `/app/knows/details?hasFlashcards=true`
    return generateRoute(path, {
        utm_content: o?.referrerScreen,
        makeKnowPrivate: o?.makeKnowPrivate,
        saveToFolderUuid: o?.saveToFolderUuid,
    })
}
export const appAiSummaryRoute = (o: { filenames: string[]; length: string; course: Course; isPublic: boolean }) => {
    const path = `/app/ai-summary`
    return generateRoute(path, {
        filenames: o.filenames.join(','),
        length: o.length,
        courseUuid: o.course.uuid,
        courseTitle: o.course.name,
        isPublic: o.isPublic,
    })
}

export const appPayoutsRoute = '/app/payouts'
export const appPayoutsHistoryRoute = '/app/payouts/history'
export const appPayoutsRequestRoute = '/app/payouts/request'
export const appUnavailableFeatureRoute = '/app/unavailable-feature'
export const appKnowRoute = (slug: string, o?: { referrerScreen?: string; referrer?: string; query?: string }) => {
    const path = `/app/knows/${slug}`
    return generateRoute(path, { utm_content: o?.referrerScreen, referrer: o?.referrer, query: o?.query })
}
export const appSchoolGptRoute = '/app/school-gpt'
export const appKnowerProfileRoute = (knower: Knower): string =>
    `/app/knowers/${formatPath(knower.user.name)}-${knower.uuid}/profile`

// Profile
export const profileRoute = '/profile'
export const profilePayoutRoute = '/profile/payouts'
export const profilePayoutRequestRoute = '/profile/payouts/request'
export const profilePayoutHistoryRoute = '/profile/payouts/history'
export const knowerProfileRoute = (knower: Knower): string =>
    `/knowers/${formatPath(knower.user.name)}-${knower.uuid}/profile`
export const knowerProfileLiteralRoute = (knowerUsername: string, knowerUuid: string) =>
    `/knowers/${formatPath(knowerUsername)}-${knowerUuid}/profile`
export const profilePayoutsCompetitionRoutePattern = '/profile/payouts/competition'
export const profilePayoutsCompetitionRoute = (accessToken: string) =>
    `/profile/payouts/competition?accessToken=${accessToken}`
export const profilePayoutsCompetitionFormRoutePattern = '/profile/payouts/competition-form'
export const profilePayoutsCompetitionFormRoute = (accessToken: string) =>
    `/profile/payouts/competition-form?accessToken=${accessToken}`

// Legal
export const legalImprintRoute = '/legal/imprint'
export const legalPrivacyRoute = '/legal/privacy'
export const legalTOSRoute = '/legal/tos'
export const legalGuidelinesRoute = '/legal/guidelines'
export const legalContactRoute = '/legal/contact'
export const legalTOSKnowerRoute = '/legal/tos-knower'
export const legalExpansionContestRoute = '/legal/contest'

// Plus Plan
export const proPlanRoute = '/pro-plan'
export const proPlanOptionRoute = (o?: { referrerScreen?: string; section?: string }) => {
    const path = '/pro-plan'
    return `${generateRoute(path, { utm_content: o?.referrerScreen })}${o?.section ? `#${o.section}` : ''}`
}

// export const plusPlanDownloadOptionRoute = (o?: { referrerScreen?: string }) => {
//     const path = '/pro-plan/download-know'
//     return generateRoute(path, { referrerScreen: o?.referrerScreen })
// }

export const proPlanSuccessRoute = (o: { subscriptionUuid: string; subscriptionCode: string; referrerScreen?: string }) =>
    `/pro-plan/success?subscriptionUuid=${o.subscriptionUuid}&subscriptionCode=${o.subscriptionCode}&utm_content=${
        o.referrerScreen ?? ''
    }`
export const proPlanSofortRedirectRoute = (o: {
    priceId: string
    productId: string
    paymentMethod: string
    subscriptionUuid: string
    subscriptionCode: string
    referrerScreen?: string
}) =>
    `/pro-plan/sofort-redirect?priceId=${o.priceId}&productId=${o.productId}&paymentMethod=${
        o.paymentMethod
    }&subscriptionCode=${o.subscriptionCode}&subscriptionUuid=${o.subscriptionUuid}&utm_content=${o.referrerScreen ?? ''}`

export const proPlanPaypalRedirectRoute = (o: {
    priceId: string
    productId: string
    subscriptionUuid: string
    subscriptionCode: string
    clientSecret: string
    referrerScreen?: string
}) =>
    `/pro-plan/paypal-redirect?priceId=${o.priceId}&productId=${o.productId}&subscriptionCode=${
        o.subscriptionCode
    }&subscriptionUuid=${o.subscriptionUuid}&setup_intent_client_secret=${o.clientSecret}&utm_content=${
        o.referrerScreen ?? ''
    }`

export const proPlanActivateRoute = '/pro-plan/activate'
export const proPlanActivateOptionRoute = (o: {
    subscriptionUuid: string
    subscriptionCode: string
    referrerScreen?: string
    omitRedirect?: boolean
}): string =>
    `/pro-plan/activate?subscriptionCode=${o.subscriptionCode}&subscriptionUuid=${o.subscriptionUuid}&utm_content=${
        o.referrerScreen ?? ''
    }${
        // adding redirect to activate page in case user decides to register and then we would be redirected back to the activate page
        !o.omitRedirect
            ? `&redirect=${proPlanActivateOptionRoute({
                  subscriptionCode: o.subscriptionCode,
                  subscriptionUuid: o.subscriptionUuid,
                  referrerScreen: o.referrerScreen,
                  omitRedirect: true,
              })}`
            : ''
    }`

//Subjects

export const subjectsTopicRoute = (subject: Subject) => `/subjects#${formatPath(subject.name)}`

export const subjectsKnowsRoute = (subjectName: string, subjectId: number) =>
    `/subjects/${formatPath(`${subjectName} ${subjectId}`)}`

export const studyGuideRoute = (studyGuideSlug: string) => `/subjects/study-guide/${studyGuideSlug}`
export const studyGuideRoutePattern = '/subjects/study-guide/[slug]'

export const taxonomyLevelOne = (topic: string) => `/topics/${topic}`

export const taxonomyLevelTwo = (levelOneTopic: string, levelTwoTopic: string) => `/topics/${levelOneTopic}#${levelTwoTopic}`

export const taxonomyLevelThree = (levelOneTopic: string, topic: string) => `/topics/${levelOneTopic}/${topic}`

export const seoSubjectsRoute = '/subjects'

// Others
export const transferedKnowRoute = '/sync'
export const chatRoute = '/chat'
export const chatPartnerRoute = (uuid: string, o?: { prefillWithTutoringUpselling?: boolean }) =>
    `/chat?chatUuid=${uuid}${o?.prefillWithTutoringUpselling ? `&prefillWithTutoringUpselling=true` : ''}`
export const shareChatRoute = (uuid: string, o?: { referrer: string }) =>
    `/chats/${uuid}${o?.referrer ? `&referrer=share` : ''}`
export const homepageRoute = '/'
export const instagramURL = (instagramUsername: string) => `https://www.instagram.com/${instagramUsername}`
export const tiktokURL = (tiktokUsername: string) => `https://www.tiktok.com/@${tiktokUsername}`

export const searchResultRoute = '/knows/search'
export const searchResultRouteOptions = (
    searchQuery: string,
    subjectId?: number | string,
    o?: { referrerScreen?: string }
) =>
    `/knows/search?query=${searchQuery}${subjectId ? `&subjectId=${subjectId}` : ''}${
        o?.referrerScreen ? `&utm_content=${o.referrerScreen}` : ''
    }`

export const blogRoute = `/blog`
export const blogPostRoute = (slug: string) => `/blog/${slug}`

// Redirects
export const learningBotRedirectRoute = `/learning-bot/[uuid]/info`
export const adsSurveyRedirectRoute = `/ads-survey-redirect`
export const pollsRedirectRoute = `/polls/[uuid]`
export const chatsRedirectRoute = `/chats/[uuid]`
export const referralRedirectRoute = `/referral/[username]`
export const userTopicQuizRedirectRoute = `/user-topic/[uuid]/quiz`
export const usersRedirectRoute = `/users/[uuid]`

// University
export const universityExpansionPage = '/university'
export const universityAiSummaryPage = '/university/ai-summary'
export const universityUniGptPage = '/university/uni-gpt'
export const universityMockExamPage = '/university/mock-exam'

export function formatPath(s: string): string {
    return s
        .toLowerCase()
        .replace(/\s/g, '-')
        .replace(/[^a-zA-Z0-9-]/g, '')
}

export const generateRoute = (slug: string, query: Record<string, string | boolean | number | undefined>) => {
    const params = Object.entries(query).filter(([_, v]) => !!v)
    const searchParams = new URLSearchParams(params as string[][])
    return `${slug}${params?.length ? `?${searchParams}` : ''}`
}
